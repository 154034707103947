import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { getImageUrl, initPage } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";

import Section from "../Section";

const identifier = "lpEbook";

function EbookThankyouLandingPage({ locale }) {
  const [page, setPage] = useState({
    identifier: identifier,
    nav: "ebook",
    locale: locale,
    parts: { mainPageSections: [], mainPageSpecialSections: [] },
    ctas: {},
  });

  const [sections, setSections] = useState(null);

  const init = () => {
    initPage(page, (page) => {
      setPage({ ...page });
      const foundSections = {};
      if (page.parts.mainPageSpecialSections) {
        Object.keys(page.parts.mainPageSpecialSections).map((sectionKey) => {
          if (sectionKey.substring(0, 6) === "ebook.")
            foundSections[sectionKey] =
              page.parts.mainPageSpecialSections[sectionKey];
        });
        setSections(foundSections);
      }
    });
  };

  useEffect(init, []);

  if (!page.data) return <Loading page={page} fullscreen />;

  const { data } = page;

  return (
    <div className={`page main-page landingpage page-${page.identifier}`}>
      <MainLayout page={page}>
        <Section>
          <Grid>
            <Row margin="xy">
              <Cell sm={24} md={12} mdo={3}>
                {data.PreTitle && (
                  <div className="page-pretitle">{data.PreTitle}</div>
                )}
                <h1>{data.Title}</h1>
                {data.ThankyouTitle && data.ThankyouText ? (
                  <div
                    className="success callout"
                    style={{ marginLeft: "-10px" }}
                  >
                    <h3>{data.ThankyouTitle}</h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: data.ThankyouText }}
                    />
                  </div>
                ) : null}
              </Cell>
              <Cell sm={24} md={6}>
                {data.TitleImage && (
                  <img src={getImageUrl(data.TitleImage)} alt="" />
                )}
              </Cell>
            </Row>
          </Grid>
        </Section>
      </MainLayout>
    </div>
  );
}

export default EbookThankyouLandingPage;
